import team1 from "../img/team-1.jpg"
import team2 from "../img/team-2.jpg"
import team3 from "../img/team-3.jpg"
import team4 from "../img/team-4.jpg"



let Missiondata = {
    headerCard : {
        mainTitle:"Our Mission",
        aboutUsMainContent:["We prioritise the success of our clients above everything else. By understanding their unique challenges and objectives, we strive to provide tailored solutions that meet their needs. Our client's success is our measure of success. Our goal is to establish lasting partnerships. Based on trust and mutual growth."],
        aboutuslist:["Skilled Instructors","Online classNamees","Online classNamees","International Certificate","Skilled Instructors","Online classNamees","International Certificate"]
    },servicedata:[{
        icon:<i className="fa fa-3x fa-globe text-primary mb-4" />,
        title:"Skilled Instructors" ,
        description:"This is a sample card with a long description that will be truncated.",
    },{
        icon:<i className="fa fa-3x fa-home text-primary mb-4"  />,
        title:"Online Classes" ,
        description:"This is a sample card with a long description that will be truncated.",
    },{
        icon:<i className="fa fa-3x fa-globe text-primary mb-4" />,
        title:"Home Projects" ,
        description:"This is a sample card with a long description that will be truncated.",
    },{
        icon:<i className="fa fa-3x fa-book-open text-primary mb-4" />,
        title:"Book Library" ,
        description:"This is a sample card with a long description that will be truncated.",
    }]

}

export {Missiondata}