let cource1 
export let footerdata = {
    links:[{
        linkname:"Home",
        href:"/"
    },{
        linkname:"About",
        href:"/about"
    },{
        linkname:"Contact",
        href:"/contact"
    }],
    address:"326, 3rd Floor, Tower B, Atlantis k-10 Sarabhai Main Road, Vadodara 390007, Gujarat, India.",
    number:"+91 9426423318",
    email:"couponlocusinfo@gmail.com",
    email2:"info@couponlocus.in",
    socialMedia:{
        twitter:"https://twitter.com/couponlocusin",
        facebook:"https://www.facebook.com/couponlocus/",
        instagram:"https://www.instagram.com/couponlocusin/",
        linkedin:"https://www.linkedin.com/company/couponlocus/"
    },

    service:[{
        linkname:"Website Development & Designing",
        href:"/websiteDevelopmentAndDesigning"
    },{
        linkname:"Mobile Application Development",
        href:"/mobileApplicationDevelopment"
    },{ 
        linkname:"Digital Marketing",
        href:"/digitalMarketing"
    },{
        linkname:"Branding",
        href:"/branding"
    },{
        linkname:"Interface Design",
        href:"/interfaceDesign"
    },{
        linkname:"Google Ads",
        href:"/googleAds"
    },{
        linkname:"SEO Services",
        href:"/seoServices"
    },{
        linkname:"Hosting & Gateway Services",
        href:"/hostingAndGatewayServices"
    }]
} 