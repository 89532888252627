import React from 'react'
import { Header } from "../Header/Header"
import images from "../img/Hosting-&-Gateway-Services_.png"
import { Services_title } from './Services_title'
import { Services_content } from './services_content'
import { Services_content_right } from './services_content_right'

import img from "../img/Hosting & Gateway Services/Corporate-Mailing-solutions.png"
import img1 from "../img/Hosting & Gateway Services/Domain-Name-Registration.png"
import img2 from "../img/Hosting & Gateway Services/Payment-Gateway-Integration.png"
import img3 from "../img/Hosting & Gateway Services/SSL-Certificates.png"
import img4 from "../img/Hosting & Gateway Services/Types-of-Bulk-SMS-Service.png"
import img5 from "../img/Hosting & Gateway Services/Website-Security.png"


function HostingAndGatewayServices() {
    let Services_title_list = {
        h1:"Hosting & Gateway Services, Coupon Locus",
        p1:"Coupon Locus offers innovative, secure Information Technology solutions. Helping organisations improve their Hosting & Gateway Services.",
        p2:"Coupon Locus gives the best-Hosting Services. Web facilitating is helping that permit affiliations and individuals to post a site or page onto the web. A web host, or web facilitating expert association, This business provides advances and loans. Organisations expected the site or page to be seen on the web.",
        h2:""
    }

    let services_content_list_1 = {
        title: "Domain Name Registration",
        contact: "Domain name registration is the method of finding a web address and joining to utilise it afterwards. You don't claim the domain, yet whenever you've enrolled it, it's yours, however long you keep paying the yearly enlistment charge.",
        li: [],
        img: img1
    }
    let services_content_list_2 = {
        title: "Payment Gateway Integration",
        contact: "A Payment Gateway creates a safe pathway between clients and businesses to work safely with instalments. It includes the verification of the two players from the banks in question. As an owner, you can acknowledge instalments from clients on your site and portable applications involving the Razor Pay Instalment Door.",
        li: [],
        img: img2
    }
    let services_content_list_3 = {
        title: "Corporate Mailing solutions",
        contact: "Corporate Mailing Solutions are intended for corporate organisations who want to have their mailing-the-board arrangements with improved business correspondences and collaboration. The most exact and effective email arrangement will make the whole business network generally coordinated and paperless.",
        li: [],
        img: img
    }
    let services_content_list_4 = {
        title: "Bulk SMS Services",
        contact: "Bulk SMS is a help that empowers sending countless SMS messages to an expansive crowd - without a moment's delay. This is a powerful help to use in your business to deliver SMS messages securely, rapidly, and directly to your clients worldwide.",
        li: [],
        img: img4
    }
    let services_content_list_5 = {
        title: "Types of Bulk SMS Service",
        contact: "",
        li: ["Digimiles", "Gupshup" , "Infobip" , "Text Nearby" , "Msg91" , "2Factor" ,"Kapsystem", "Fast2SMS.com"],
        img: img4
    }
    let services_content_list_6 = {
        title: "SSL Certificates",
        contact: "SSL certificates empower sites to utilise HTTPS, which is more secure than HTTP. An SSL certificate is an information document facilitated in a site's starting point server",
        li: [],
        img: img3
    }
    let services_content_list_7 = {
        title: "Website Security",
        contact: "Website security refers to the actions taken to get a site from cyberattacks. That could include shielding a site from programmers, malware, tricks or phishing, and blunders. Website security is an ongoing process: a cycle fundamental to dealing with a website",
        li: [],
        img: img5
    }
  return (
    <>
    <Header pagename="Hosting And Gateway Services" image={images} path={[{link:"/",text:"Home"},{link:"/hostingAndGatewayServices",text:"Hosting And Gateway Services"}]}/>
    <div>
    <Services_title {...Services_title_list}></Services_title>
    <div className="pt-3"></div>
        <div className="bg-light my-4 py-5">
                    <div className="container">
                        <Services_content {...services_content_list_1} ></Services_content>
                    </div>
                </div>
                <div className="my-4 py-2">
                    <div className="container">
                        <Services_content_right {...services_content_list_2} ></Services_content_right>
                    </div>
                </div>
                <div className="bg-light my-4 py-5">
                    <div className="container">
                        <Services_content {...services_content_list_3} ></Services_content>
                    </div>
                </div>
                <div className="my-4 py-2">
                    <div className="container">
                        <Services_content_right {...services_content_list_6} ></Services_content_right>
                    </div>
                </div>
                <div className="bg-light my-4 py-5">
                    <div className="container">
                        <Services_content {...services_content_list_5} ></Services_content>
                    </div>
                </div>
                <div className="my-4 py-2">
                    <div className="container">
                        <Services_content_right {...services_content_list_7} ></Services_content_right>
                    </div>
                </div>
    </div>
    </>
  )
}

export {HostingAndGatewayServices}