import images from "../img/cat-1.jpg"
import { Header } from "../Header/Header"
import {Contact_us} from "../Contact/Contact_us"
function ContactUs(){
    return (<>
      <Header pagename="Contact Us" image={images} path={[{link:"/",text:"Home"},{link:"/contact",text:"Contact"}]}/>
        <Contact_us/>
    </>)
}


export {ContactUs}