
export const PageNotFound = () => {
  return (
    <>
    
        <div className="container py-5">
            <div className="row justify-content-center">
                <div className="col-lg-6 d-flex align-items-center flex-column">
                     <i className="bi bi-exclamation-triangle display-1 text-primary"></i>   
                     <h1 className="display-1">404</h1>
                     <h1 className="mb-4">Page Not Found</h1>
                     <p className="mb-4 text-center">We’re sorry, the page you have looked for does not exist in our website! Maybe go to our home page or try to use a search?</p>
                     <button type='button' className='btn btn-primary  error_page_btn'>Go Back To Home</button>
                </div>
            </div>
        </div>
        
    </>
  )
}
