import {footerdata} from "./footerdata"
import { Link } from "react-router-dom"
import img from '../img/CouponLocus_WebsiteLogo_White.png'

function Footer(){

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    return(<>
        <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
            <div className="col-lg-3 col-md-6">
            <span className=" d-flex align-items-center navbar-brand"><a className="" href="/"><img src={img}/></a></span>
                    <h4 className="text-white my-3 ">About us</h4>
                    <p>Enhancing your business with innovative IT solutions.</p>
                    {/* <div className="position-relative mx-auto" style={{maxWidth:"400px"}}>
                        <input className="form-control border-0 w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email"/>
                        <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
                    </div> */}
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-white mb-3">Quick Link</h4>
                    {footerdata.links.map((e,i) =>{
                        return (
                            <Link className="btn btn-link" onClick={scrollToTop} key={i + 142536} to={e.href}>{e.linkname}</Link>
                        )
                    })}
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-white mb-3">Services</h4>
                    {footerdata.service.map((e,i)=>{
                        return (
                            <Link onClick={scrollToTop} className="btn btn-link" key={i + 142536} to={e.href}>{e.linkname}</Link>
                        )
                    })}
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-white mb-3">Contact</h4>


                     <div className="d-flex gap-2 mb-2"><i className="fa fa-map-marker-alt me-3"></i>{footerdata.address}</div>
                   <a href={`tel:${footerdata.number}`} className="text-white"> <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>{footerdata.number}</p></a>
                    <a href={`mailto:${footerdata.email}`} className="text-white"><p className="mb-2"><i className="fa fa-envelope me-3"></i>{footerdata.email}</p></a>
                    <a href={`mailto:${footerdata.email2}`} className="text-white"><p className="mb-2"><i className="fa fa-envelope me-3"></i>{footerdata.email2}</p></a> 
                    
                </div>
            </div>
        </div>
        <div className="container ">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a className="border-bottom" href="#">Coupon Locus</a>, All Right Reserved.

                        {/* Designed By <a className="border-bottom" href="https://htmlcodex.com">HTML Codex</a> */}
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                    <div className="d-flex justify-content-end pt-2">
                        <a className="btn btn-outline-light btn-social" target="_blank" href="https://twitter.com/couponlocusin"><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-outline-light btn-social" target="_blank" href="https://www.facebook.com/couponlocus/"><i className="fab fa-facebook-f"></i></a>
                        {/* <a className="btn btn-outline-light btn-social" target="_blank" href=""><i className="fab fa-youtube"></i></a> */}
                    
                        <a className="btn btn-outline-light btn-social" target="_blank" href="https://www.instagram.com/couponlocusin/"><i className="fab fa-instagram"></i></a>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="">
    <a href="#" className="btn btn-lg  btn-lg-square back-to-top text-decoration-none btn_hover"><i className="bi bi-arrow-up" id="hv"></i></a>
    </div>
    </>)
}

export {Footer}