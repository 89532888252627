import React from 'react'
import { useEffect } from 'react';

function Contact_us() {
    useEffect(() => {
        document.title = "Contact Us || Coupon Locus"
     }, []);
  return (
    <>
    <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="section-title bg-white text-center text-primary px-3">Contact Us</h6>
                <h1 className="mb-5">Contact For Any Query</h1>
            </div>
            <div className="row g-4">
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className='mb-3'>Get In Touch</h5>
                    {/* <p className="mb-4">The contact form is currently inactive. Get a functional and working contact form with Ajax & PHP in a few minutes. Just copy and paste the files,
                     add a little code and you're done. <a href="https://htmlcodex.com/contact-form">Download Now</a>.</p> */}
                    <div className="d-flex align-items-center mb-3">
                        <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{width: "50px", height: "50px"}}>
                            <i className="fa fa-map-marker-alt text-white"></i>
                        </div>
                        <div className="ms-3">
                            <h5 className="text-primary">Office</h5>
                            <p className="mb-0">326, 3rd Floor, Tower B, Atlantis k-10 Sarabhai Main Road, Vadodara 390007, Gujarat, India.</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                        <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{width: "50px", height: "50px"}}>
                            <i className="fa fa-phone-alt text-white"></i>
                        </div>
                        <div className="ms-3">
                            <h5 className="text-primary">Mobile</h5>
                            <p className="mb-0">+91 94264 23318</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{width: "50px", height: "50px"}}>
                            <i className="fa fa-envelope-open text-white"></i>
                        </div>
                        <div className="ms-3">
                            <h5 className="text-primary">Email</h5>
                            <p className="mb-0">couponlocusinfo@gmail.com</p>
                            <p className="mb-0"> info@couponlocus.in</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <iframe className="position-relative rounded w-100 h-100"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7382.041139909056!2d73.1688741!3d22.3150618!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc9b5487381b7%3A0x5101c638360219ea!2sCoupon%20locus!5e0!3m2!1sen!2sin!4v1686919246955!5m2!1sen!2sin"
                        frameBorder="0" style={{minHeight: "300px", border:"0"}}  aria-hidden="false"
                        tabIndex="0"></iframe>

                    
                </div>
                {/* <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                    <form>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="name" placeholder="Your Name"/>
                                    <label htmlFor="name">Your Name</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input type="email" className="form-control" id="email" placeholder="Your Email"/>
                                    <label htmlFor="email">Your Email</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="subject" placeholder="Subject"/>
                                    <label htmlFor="subject">Subject</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-floating">
                                    <textarea className="form-control" placeholder="Leave a message here" id="message" style={{height: "150px"}}></textarea>
                                    <label htmlFor="message">Message</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div> */}
            </div>
        </div>
    </div>
    </>
  )
}

export {Contact_us}