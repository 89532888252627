import React from 'react'
import { ServiceBox } from './ServiceCard'
import "./service.css"
import { useState } from 'react'

export default function Service(props) {
  let [data,setData] = useState(props.data)
  return (
    <>  
        <div className="container-xxl py-5">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="section-title bg-white text-center text-primary px-3">What We Do</h6>
                <p className="mb-4">We excel in developing customised software solutions to meet the specific 
                needs of our clients. Our experienced team of developers uses cutting-edge technologies
                 and follows industry best practices to deliver high-quality software tailored to your
                  particular requirements.</p>
            </div>
        </div>
        <div className="container-xxl py-5">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 className="section-title bg-white text-center text-primary px-3">What We Deliver</h6>
                <h1 className="mb-4">IT Services</h1>
            </div>

            <div className="container">
                <div className="row g-4"> 
                  {  data.map((e,i)=> {
                      return( <ServiceBox icon={e.icon} 
                      key={i}
                      link={e.link}
                      title={e.title} 
                      description={e.description}
                      descriptionLimit={150}
                      linkText="Read More"
                    />)
                    })
                  }                  
                </div>
            </div>
        </div>
    </>
  )
}


export {Service}


