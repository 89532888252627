import React from 'react';
import './bootstrap.min.css'
import './font-size.css'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';


import Navbar from "./component/navbar/Navbar";
import { useEffect } from "react";
// import './component/css/bootstrap.min.css'
import { AdvanceCard } from "./component/advancecard/advanceCard";
import { CourcesCard } from "./component/courcesCard/CourcesCard";
import {PeopleCard} from "./component/peopleCard/PeopleCard";
import { Footer } from "./component/footer/footer";
import { Header } from "./component/Header/Header";
import { PageNotFound } from "./component/PageNotFound/PageNotFound";
import {About} from "./component/Aboutus/aboutus"
import {Home} from "./component/Home/Home"
import {ContactUs} from "./component/contactus/contactus"
import {WebsiteDevelopmentAndDesigning} from  "./component/service/websiteDevelopmentAndDesigning"
import {MobileApplicationDevelopment} from  "./component/service/mobileApplicationDevelopment"
import {DigitalMarketing} from  "./component/service/digitalMarketing"
import {Branding} from  "./component/service/branding"
import {InterfaceDesign} from  "./component/service/interfaceDesign"
import {GoogleAds} from  "./component/service/googleAds"
import {SeoServices} from  "./component/service/seoServices"
import {HostingAndGatewayServices} from  "./component/service/hostingAndGatewayServices"


function App() {
 
  return (
    <>
    <Router>
    <Navbar/>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/websiteDevelopmentAndDesigning" element={<WebsiteDevelopmentAndDesigning/>} />
          <Route path="/mobileApplicationDevelopment" element={<MobileApplicationDevelopment/>} />
          <Route path="/digitalMarketing" element={<DigitalMarketing/>} />
          <Route path="/branding" element={<Branding/>} />
          <Route path="/interfaceDesign" element={<InterfaceDesign/>} />
          <Route path="/googleAds" element={<GoogleAds/>} />
          <Route path="/seoServices" element={<SeoServices/>} />
          <Route path="/hostingAndGatewayServices" element={<HostingAndGatewayServices/>} />          
          <Route path="*" element={<PageNotFound/>} />

        </Routes>
      </div>
      <Footer/>
    </Router>
    
    </>
  );
}

export default App;
