import team1 from "../img/team-1.jpg"
import team2 from "../img/team-2.jpg"
import team3 from "../img/team-3.jpg"
import team4 from "../img/team-4.jpg"



let Aboutdata = {
    headerCard: {
        mainTitle: "Enhancing your business with innovative IT solutions.",
        aboutUsMainContent: ["Coupon Locus Technology is the Best Website Development Company. We have intelligent and talented web developers, and As digital marketers, we offer a unique one-stop solution to hundreds of our clients. Coupon Locus's comprehensive suite of digital services, including web design, web development, e-commerce development, digital marketing, graphic design, video creation, and more, are designed to elevate your brand and drive success in the digital landscape. Coupon locus is the Best IT Services Provider."],
        aboutuslist: ["Don't be concerned if you're just getting started and want to reach your target market online. We've got your back! Our highly qualified and experienced staff works tirelessly to suit the needs of each client. We bring originality and thorough expertise to every project to grow customer bases and revenue. We are a highly regarded and Best IT Company."]
    }, peoplecardData: [{
        img: team1,
        name: "Prateek Kushwaha",
        position: "React Developer",
        facebooklink: "#",
        twitterlink: "#",
        instagram: "#"
    }, {
        img: team2,
        name: "Raj Bodiwala",
        position: "Node Developer",
        facebooklink: "#",
        twitterlink: "#",
        instagram: "#"
    }, {
        img: team3,
        name: "Bahvesh Wagela",
        position: "Python Developer",
        facebooklink: "#",
        twitterlink: "#",
        instagram: "#"
    }, {
        img: team4,
        name: "Harsh Savariya",
        position: "Backend Developer",
        facebooklink: "#",
        twitterlink: "#",
        instagram: "#"
    }], servicedata: [{
        icon: <i className="fa fa-3x fa-globe text-primary mb-4" />,
        title: "Skilled Instructors",
        description: "This is a sample card with a long description that will be truncated.",
    }, {
        icon: <i className="fa fa-3x fa-home text-primary mb-4" />,
        title: "Online Classes",
        description: "This is a sample card with a long description that will be truncated.",
    }, {
        icon: <i className="fa fa-3x fa-globe text-primary mb-4" />,
        title: "Home Projects",
        description: "This is a sample card with a long description that will be truncated.",
    }, {
        icon: <i className="fa fa-3x fa-book-open text-primary mb-4" />,
        title: "Book Library",
        description: "This is a sample card with a long description that will be truncated.",
    }]

}

export { Aboutdata }