import React from 'react'

export const Services_title = ({h1 ,p1, p2 ,h2}) => {
  return (
    <div className="container service_itm">
            <h2>{h1}</h2>
            <p>{p1}</p>
            <p>{p2}</p>
            <h4>{h2}</h4>
        </div>
  )
}
