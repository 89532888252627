import { useState } from "react"
import  Mission_1 from "../img/about.jpg"
function Mission(props) {
    
   let [Data,updateData] = useState(props.data)
    return <>
    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{minHeight:"400px"}}>
                    <div className="position-relative h-100">
                        <img className="img-fluid position-absolute w-100 h-100" src={Mission_1} alt="" style={{objectFit:"cover"}} />
                    </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                    <h6 className="section-title bg-white text-start text-primary pe-3">Our Mission</h6>
                    <h1 className="mb-4">{Data.mainTitle}</h1>
                    {Data.aboutUsMainContent.map((e,i) => {
                        return (
                            <p key={i} className="mb-4">{e}</p>
                        )
                    })}                  
                    {/* <div className="row gy-2 gx-4 mb-4">
                    {Data.aboutuslist.map((e,i) => {
                            return <>
                                <div key={i} className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>{e}</p>
                                </div>
                            </>
                        })}
                    </div> */}
                    {/* <a className="btn btn-primary py-3 px-5 mt-2" href="">Read More</a> */}
                </div>
            </div>
        </div>
    </div>
    
    </>
}

export {Mission}