import React from 'react';
import { Link } from 'react-router-dom';

function ServiceBox(props) {

    // Description
    const { description, descriptionLimit } = props;

    const truncateDescription = (text, limit) => {
        if (text.length <= limit) {
          return text;
        }
        return text.slice(0, limit) + '...';
      };
    
    const truncatedDescription = truncateDescription(description, descriptionLimit);
    
    // button  
    const handleClick = () => {
        // Handle the click event here
        console.log('Link clicked!');
    };

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }

    return (
        <>
            <div className="col-lg-3 col-sm-6 wow fadeInUp d-flex align-items-stretch" data-wow-delay="0.1s">
            <Link to={props.link} onClick={scrollToTop} className='h-100 w-100'>
                <div className="service-item text-center h-100 w-100 pt-3">
                    <div className="p-4">   
                        <div className="icon">{props.icon}</div>
                        <h5 className="mb-3">
                            <span onClick={handleClick}>
                                {props.title}
                            </span></h5>                                   
                        {/* <p>{props.desc}</p> */}
                        <p className="card-description">{truncatedDescription}</p>
                        {/* <a className="btn btn-outline-primary py-3 px-5 mt-2 read_more_link" href="#" onClick={handleClick}>
                            {props.linkText}
                        </a>                        */}
                    </div>
                </div>
                </Link>
            </div>
        </>
    )
}

export {ServiceBox}