
import Slider_slick from "../Slider_Slick/Slider_slick"
import Slider_Slick2 from "../../Slider_Slick2/Slider_Slick2"
import {AdvanceCard} from "../advancecard/advanceCard"
import {Mission} from "../mission/mission"
import {Missiondata} from "../mission/our_mission"
import {Aboutdata} from "../Aboutus/data"
import {Courses} from "../Courses/Courses"
import {CourcesCard} from "../courcesCard/CourcesCard"
import {Service} from "../service/Service" 
import { data } from "../peopleCard/peopledata"
import {PeopleCard} from "../peopleCard/PeopleCard"
import { useEffect } from "react"
function Home(){
    useEffect(() => {
        document.title = "Coupon Locus"
     }, []);
    let servicedatalol = [{
        icon:<i className="fa fa-3x fa-globe text-primary mb-4" />,
        title:"Website Development & Designing" ,
        description:"We think differently than traditional designers, marketers, and thinkers. As a result, we begin with the end consumers. To better assist our users, we strive to empathise with them and understand their perspectives. Comprehend what 'experiences' are like. The creative side of us now has a firm foundation on which to create colour, messaging, iconography, layout, and compelling interactions that represent our knowledge.",
        link:"/websiteDevelopmentAndDesigning"
    },{
        icon:<i className="fa fa-3x fa-mobile text-primary mb-4"  />,
        title:"Mobile Application Development" ,
        description:" In ongoing patterns, portable applications have filled in noticeable quality, and all large companies have moved their client corporations to versatile. Cell phones, then again, are helpful and promptly accessible. Thus, giving clients the best experience and having appropriate correspondence with them is more straightforward.",
        link:"/mobileApplicationDevelopment"
    },{
        icon:<i className="fa fa-3x fa-shopping-cart text-primary mb-4" />,
        title:"Digital marketing" ,
        description:"An ideal digital marketing strategy includes SEO, SEM, PPC, SMO, content marketing, and social media networking, among other things. We are Google-certified. We are a Bing Ads accredited agency with a team of knowledgeable marketing professionals. Our team comprises members with unique skills, knowledge, and extensive experience in their respective marketing fields. Our team is constantly searching for new opportunities. And updated information. Creative approaches to. Connect businesses to people all over the world.",
        link:"/digitalMarketing"
    },{
        icon:<i className="fa fa-3x fa-bold text-primary mb-4" />,
        title:"Branding" ,
        description:"Allow your brand to speak for itself. We have an exceptional team of branding consultants who know how to create the spark that will keep the flame of your brand's name burning in the minds of your customers forever.",
        link:"/branding"
    },{
        icon:<i className="fa fa-3x fa-paint-brush text-primary mb-4" />,
        title:"Interface Design" ,
        description:" Interfaces that are creative, appealing, engaging, and usable are the heart of any design. We will assist you with designing unique user interfaces for any device platform, including mobile apps, tablets, PCs, and KIOSKS, based on your business requirements.",
        link:"/interfaceDesign"
    },{
        icon:<i className="fa fa-3x fa-audio-description text-primary mb-4" />,
        title:"Google Ads" ,
        description:"Google Ads permits you to exploit the advantages of web-based publicising: show your advertisements to the ideal individuals, ideally located and brilliantly. Google Promotions offers a few benefits. Arrive at new clients and develop your business with Google Promotions, Google's internet publicising program. These aides are intended to rapidly raise you to an acceptable level. Pay Per Click",
        link:"/googleAds"
    },{
        icon:<i className="fa fa-3x fa-search text-primary mb-4" />,
        title:"SEO Services" ,
        description:"Website design enhancement consists of numerous various Understanding of the components and how they work crucial to comprehending the rationale behind them. Search engine optimization (SEO) is so significant. To put it, Web optimization is vast. Improving your site's visibility leads to increased traffic. There are more effective opportunities to convert potential customers into actual ones. Clients. Past that, it is likewise an essential device for brand mindfulness, building associations with possibilities, and situating yourself as a definitive and reliable master in your field. So right away, here's the beginning and end. You want to be familiar with Search engine optimization and why it's fundamental in today's advanced world.",
        link:"/seoServices"
    },{
        icon:<i className="fa fa-3x fa-server text-primary mb-4" />,
        title:"Hosting & Gateway Services" ,
        description:"Coupon Locus gives the best-Hosting Services. Web facilitating is helping that permit affiliations and individuals to post a site or page onto the web. A web host, or web facilitating expert association, This business provides advances and loans. Organisations expected the site or page to be seen on the web.",
        link:"/hostingAndGatewayServices"
    }]
    let our_mission = Missiondata.headerCard
    
    let aboutus = Aboutdata.headerCard

    return (<>
        <Slider_slick/>
        <Service data={servicedatalol}/>
        <Mission data={our_mission}/>    
        {/* <AdvanceCard data={aboutus}/> */}
        {/* <Courses/> */}
        {/* <CourcesCard/> */}
        {/* <PeopleCard data={data} name={"Expert Instructors"} context={"Expert Instructors"}/> */}
        {/* <Slider_Slick2/> */}
    </>)
}

export {Home}