import React from 'react'
import { Header } from "../Header/Header"
import images from "../img/Mobile-Application-Development_.png"
import { Services_title } from './Services_title'
import { Services_content } from './services_content'
import { Services_content_right } from './services_content_right'
import img from '../img/Mobile Application Development/iOS-Development.png'
import img2 from '../img/Mobile Application Development/Android-Development.png'
import img3 from '../img/Mobile Application Development/PhoneGap-App-Development.png'
import img4 from '../img/Mobile Application Development/React-Development.png'
import img5 from '../img/Mobile Application Development/Windows-Mobile-App-Development.png'

function MobileApplicationDevelopment() {
    let Services_title_list = {
        h1:"Mobile Application Development (coupon locus)",
        p1:"In the digital age, intuitive designs and engaging user experiences distinguish our apps. Acodez's apps help your organisation reach its objectives by providing a smooth experience based on international standards and out-of-the-box innovations.",
        p2:"",
        h2:""
    }

    
    let services_content_list_1 = {
        title: "Android Development",
        contact: " Creating smooth, dependable, scalable, and robust apps within the time limit specified while maintaining 100% customer satisfaction. Our mobile app team is highly skilled—development experts specialising in Android app design and development.",
        li: [],
        img: img2
    }
    let services_content_list_2 = {
        title: "iOS Development",
        contact: " Whether our client's business requires a new iPhone app or changes, We have a skilled team of iPhone development experts for our current app., proven their excellence at developing apps from the ground up and modifying existing ones to meet end-user requirements.",
        li: [],
        img: img
    }
    let services_content_list_3 = {
        title: "React Development: Making the actual mobile app",
        contact: "We develop and design React Native apps indistinguishable from those designed in Objective-C or Java by incorporating the perfection and brilliance of the UI building blocks in iOS and Android apps.",
        li: [],
        img: img4
    }
    let services_content_list_4 = {
        title: "Windows Mobile App Development",
        contact: "We produce customised Windows phone apps to match your company goals using tailor-made design solutions and tried-and-true approaches while analysing the newest industry trends.",
        li: [],
        img: img5
    }
    let services_content_list_5 = {
        title: "PhoneGap App Development",
        contact: "We give Phonegap App development solutions, your one-stop solution for all your company needs, by incorporating robust and resilient backends with open-source platforms and ensuring these work seamlessly across multiple mobile.",
        li: [],
        img: img3
    }
    let services_content_list_6 = {
        title: "Xamarin App Development",
        contact: "Xamarin website! We are a team of skilled developers specialising in Xamarin app development, a robust framework that allows us to create cross-platform mobile applications with native-like performance and user experience. Here's a description of our Xamarin development.",
        li: [],
        img: ""
    }
  return (
    <>
    <Header pagename="Mobile Application Development" image={images} path={[{link:"/",text:"Home"},{link:"/mobileApplicationDevelopment",text:"Mobile Application Development"}]}/>
    <div>
    <Services_title {...Services_title_list}></Services_title>
    <div className="container pt-5 pb-3 remove_class_section_title">
            <div className="d-flex justify-content-center mb-3">
            <h5 className="section-title bg-white text-center text-dark px-3">Mobile Application Development website</h5>
            </div>
            <p> We specialise in creating innovative and user-centric mobile. We develop applications for different platforms, such as iOS and Android. Our dedicated team of mobile app developers combines technical expertise with creative flair to deliver exceptional mobile experiences. This company specialises in developing—mobile applications</p>
            <p>We present a distinct strategy on which mobile app design and development are carried out. Our mobile app development consultants collaborate. I work with the UX and UI design teams to offer assistance. Most excellent user experience while providing an intuitive interface to end users. Our mobile app designs investigate the most recent industry trends, technology, and tried-and-true methodologies to ensure a seamless, straightforward, and aesthetically pleasing flow in the final prototype.</p>
        </div>
        <div className="container pt-5 pb-3 remove_class_section_title">
        <div className="d-flex justify-content-center">
            <h5 className="section-title bg-white text-center text-dark px-3">Our Mobile App Development Services</h5>
            </div>
        </div>
        <div className="bg-light my-4 py-5">
            <div className="container">
                <Services_content {...services_content_list_1} ></Services_content>
            </div>
        </div>
       
        <div className="my-4 py-2">
            <div className="container">
                <Services_content_right {...services_content_list_2} ></Services_content_right>
            </div>
        </div>
        <div className="bg-light my-4 py-5">
            <div className="container">
                <Services_content {...services_content_list_3} ></Services_content>
            </div>
        </div>
       
        <div className="my-4 py-2">
            <div className="container">
                <Services_content_right {...services_content_list_4} ></Services_content_right>
            </div>
        </div>
        <div className="bg-light my-4 py-5">
            <div className="container">
                <Services_content {...services_content_list_5} ></Services_content>
            </div>
        </div>
    </div>
    </>
  )
}

export {MobileApplicationDevelopment}