import React from 'react'
import { Header } from "../Header/Header"
import images from "../img/Digital-Marketing-Development-IT-Service-Provider_.png"
import { Services_title } from './Services_title'
import { Services_content } from './services_content'
import { Services_content_right } from './services_content_right'

import img from "../img/Digital Marketing Development/Analytics-Consultation.png"
import img1 from "../img/Digital Marketing Development/Email-Marketing.png"
import img2 from "../img/Digital Marketing Development/Management-of-Online-Reputation.png"
import img3 from "../img/Digital Marketing Development/SEO-(Search-Engine-Optimization).png"
import img4 from "../img/Digital Marketing Development/Search-Engine-Marketing.png"
import img5 from "../img/Digital Marketing Development/Social-Media-Marketing.png"
import img6 from "../img/Digital Marketing Development/Social-Media-Marketing.png"



function DigitalMarketing() {
    let Services_title_list = {
        h1:"Digital Marketing Development IT Service Provider | Coupon locus",
        p1:"Digital Marketing website! Our team consists of skilled professionals in the field of digital marketing. I assist businesses in enhancing their online presence and reaching their marketing objectives.",
        p2:"An ideal digital marketing strategy includes Some important digital marketing strategies, including various digital marketing methods, including SEO, SEM, PPC, SMO, content marketing, and social media networking, among other things. We are Google-certified marketing professionals as well as a Bing ads accredited agency. Each digital marketing team member has extensive experience and expertise in their marketing field. We constantly seek new and creative ideas—methods to improve. Connect businesses to people worldwide.",
        h2:"We provide the following Digital Marketing Services"
    }

    let services_content_list_1 = {
        title: "SEO (Search Engine Optimization)",
        contact: "We have a well-planned SEO approach that will meet your company's needs. Using our powerful keyword research analogy to optimise your site will propel it to the top of search engine results.",
        li: [],
        img: img3
    }
    let services_content_list_2 = {
        title: "SEM & PPC (Search Engine Marketing)",
        contact: "The audience drives SEM. Our SEM strategy is based on determining your target audience and then targeting them. We produce eye-catching commercials to entice, engage, and convert your target audience.",
        li: [],
        img: img4
    }
    let services_content_list_3 = {
        title: "Social Media Marketing",
        contact: "We handle the day-to-day. I am capable of assisting you in managing your social media. Accounts. Which includes creating content. Scheduling, posting, and engagement. Our team develops compelling and visually appealing content that aligns with your brand identity and resonates with your target audience. We engage with your followers, respond to comments and messages, and monitor the performance of your social media presence.",
        li: [],
        img: img5
    }
    let services_content_list_4 = {
        title: "Content Marketing ",
        contact: "Our fantastic team of experts constantly seeks innovative improvement methods. Create excellent content marketing strategies that will lead visitors to your brand.",
        li: [],
        img: img6
    }
    let services_content_list_5 = {
        title: "Email Marketing",
        contact: "Email marketing is a commercial process that requires experience and skills to execute successfully. Many of our clients who use email marketing to interact with customers and increase their customer count have used specific tried-and-true tactics.",
        li: [],
        img: img1
    }
    let services_content_list_6 = {
        title: "Management of Online Reputation",
        contact: "Notoriety is a word that can be made exclusively through trust. We make online standing administration crusades that will create trust through certifiable techniques that are attempted and demonstrated for most of the presumed business firms running effectively online.",
        li: [],
        img: img2
    }
    let services_content_list_7 = {
        title: "Analytics Consultation",
        contact: "Acodez specialises in Analytics Consultation. Thank you to our team of skilled specialists who devised an excellent approach for analysing, changing, and improving business objectives, thereby assisting you in achieving your business goals and success.",
        li: [],
        img: img
    }
  return (
    <>
    <Header pagename="Digital Marketing" image={images} path={[{link:"/",text:"Home"},{link:"/digitalMarketing",text:"Digital Marketing"}]}/>
    <div>
        <Services_title {...Services_title_list}></Services_title>
        <div className="bg-light my-4 py-5">
                    <div className="container">
                        <Services_content {...services_content_list_1} ></Services_content>
                    </div>
                </div>
                <div className="my-4 py-2">
                    <div className="container">
                        <Services_content_right {...services_content_list_2} ></Services_content_right>
                    </div>
                </div>
                <div className="bg-light my-4 py-5">
                    <div className="container">
                        <Services_content {...services_content_list_3} ></Services_content>
                    </div>
                </div>
                <div className="my-4 py-2">
                    <div className="container">
                        <Services_content_right {...services_content_list_5} ></Services_content_right>
                    </div>
                </div>
                <div className="bg-light my-4 py-5">
                    <div className="container">
                        <Services_content {...services_content_list_6} ></Services_content>
                    </div>
                </div>
                <div className="my-4 py-2">
                    <div className="container">
                        <Services_content_right {...services_content_list_7} ></Services_content_right>
                    </div>
                </div>
                <div className="bg-light my-4 py-5">
                    <div className="container">
                        <Services_content {...services_content_list_4} ></Services_content>
                    </div>
                </div>
                
    </div>
    </>
  )
}

export {DigitalMarketing}