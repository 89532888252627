import React from 'react'

export const Services_content = (props) => {

  return (
            <div className="row">
                <div className="col-md-6 col-12 d-flex align-items-center">
                    <div className="w-100">
                        <h3 className='mb-3'>{props.title}</h3>
                        <h6 className='text-secondary fw-semibold content_pr'>{props.contact}</h6>
                        <div className="row mt-3">
                            <div className="col-12">
                                <ul className=''>
                                    {props.li.map((li,i) => {
                                        return(
                                            <li className='list-styled-circle' key={i++}><span>{li}</span></li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 d-md-block d-none">
                    <div className="h-100 d-flex align-items-center">
                        <div className="w-100">
                        <img src={props.img}  className='img-fluid Services_img_responsive' alt="" />
                        </div>
                    </div>
                </div>
            </div>
  )
}
