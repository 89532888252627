import React from 'react'
import { Header } from "../Header/Header"
import images from "../img/Google_Ads_Services_.png"
import { Services_title } from './Services_title'
import { Services_content } from './services_content'
import { Services_content_right } from './services_content_right'

import img from "../img/Google Ads Services/Remarketing.gif"
import img1 from "../img/Google Ads Services/YouTube-Ads.gif"

function GoogleAds() {

    let Services_title_list = {
        h1: "Google Ads Services l Coupon Locus",
        p1: "Coupon Locus offers innovative, secure Information Technology solutions. They are helping organisations improve their Google Ads Services.",
        p2: "Google Ads permits you to exploit the advantages of web-based publicising: show your advertisements to the ideal individuals, ideally located and brilliantly. Google Promotions offers a few benefits. Arrive at new clients and develop your business with Google Promotions, Google's internet publicising program. These aides are intended to rapidly raise you to an acceptable level. Pay Per Click",
        h2: ""
    }
    // let services_content_list_1 = {
    //     title: "Landing Page & Conversion Rate Optimization",
    //     contact: `A landing page is an independent page intended to change over clients into qualified leads. People may "land" on these pages using Google search, promotions (PPC or virtual entertainment), or a source of inspiration (CTA) put on your site, blog, or in a separate email. Even though there are various kinds of greeting pages (erring on that in a moment), one thing they all share, practically speaking, is their particular concentration.`,
    //     li: [],
    //     img: ""
    // }
    // let services_content_list_2 = {
    //     title: "Single deal",
    //     contact: "Landing pages empower clients to learn everything without exception about your organisation and items. Every one of the advantages. Every one of the highlights. Everything. Presentation pages, then again, are constantly centred around a solitary mission to advance a particular item, administration, or offer.",
    //     li: [],
    //     img: ""
    // }
    // let services_content_list_3 = {
    //     title: " A single objective",
    //     contact: "While landing pages offer clients whatever number of chances as could be expected under the circumstances to draw in — buy into the pamphlet, follow via web-based entertainment, book a demo call, talk with a chatbot, look at the most recent blog entry — greeting pages drive a solitary strategy—normal greeting page CTAs including finishing up a structure or pursuing a free preliminary.",
    //     li: [],
    //     img: ""
    // }
    // let services_content_list_4 = {
    //     title: "Single Source of traffic",
    //     contact: "Since greeting pages are crusade centred, they focus on a solitary Source of traffic, arriving clients through exceptionally designated paid promotions alone. Source of traffic, frequently arriving at clients through very selected paid upgrades.",
    //     li: [],
    //     img: ""
    // }
    let services_content_list_5 = {
        title: "Remarketing",
        contact: "Google Advertisement remarketing is a type of internet publicising that empowers locales to show designated promotions to clients who have previously visited their webpage. Past guests will see these advertisements while perusing the web, watching YouTube recordings, or perusing news destinations, for instance - keeping your image top-of-mind and alluring guests to return for more. Remarketing, or retargeting, can decisively build your change rates and return on initial capital investment. This is because previous site guests who are now acquainted with your image are considerably more liable to become clients or complete other significant activities on your site.",
        li: [],
        img: img
    }

    return (
        <>
            <Header pagename="Google Ads" image={images} path={[{ link: "/", text: "Home" }, { link: "/googleAds", text: "Google Ads" }]} />
            <div className="">
                <Services_title {...Services_title_list}></Services_title>
                <div className="container pt-5 pb-3 remove_class_section_title">
                    <div className="d-flex justify-content-center mb-3">
                        <h6 className="section-title bg-white text-center text-primary px-3">Display Advertising</h6>
                    </div>
                    <p>Display Advertising is pictures, videos, or gifs displayed to users on sites or applications. Most showcase promoting utilises square, scene, or high-rise designs with images, duplicates, and a solid (CTA) to captivate clients to click. Show promotions look like advertisements in a paper or magazine but appear online. They can appear in articles, web journals, recordings, and sites shoppers peruse for data. You can focus on over two million areas utilising the Google Display Organization</p>
                </div>
            </div>
            <div className="container">
                <h5 className='mb-0'>Types of display advertising :</h5>
                <p>There are a few different kinds of show ads for organisations to browse, including:</p>

                <div className="pt-3">
                    <ul>
                        <li className='list-styled-circle'><span className='text-dark'>Remarketing advertisement :</span></li>
                        <p>Otherwise called retargeting promotions, remarketing promotions make a customised publicising experience for clients. Remarketing upgrades permit you to target specific client sections, given their moves on your site</p>
                        <li className='list-styled-circle'><span className='text-dark'> Customised advertisements :</span></li>
                        <p>These permit you to section your crowd given various variables, including socioeconomics, interests, and late buys</p>
                        <li className='list-styled-circle'><span className='text-dark'> Logically designated advertisements :</span></li>
                        <p> Relevantly selected promotions are utilised when there are sure watchwords, points, dialects, or perusing accounts of clients. For these promotions, clients are portioned in light of various boundaries.</p>
                        <li className='list-styled-circle'><span className='text-dark'> Webpage put advertisements:</span></li>
                        <p>These promotions permit you to focus on the sites where your upgrades will be set. Webpage promotions can be joined with different types of show promotions, including tailored advertisements, to give clients an excellent encounter while focusing on their number one web-based distributions and sites</p>
                    </ul>
                </div>
            </div>
            {/* <div className="">
                <div className="bg-light my-4 py-5">
                    <div className="container">
                        <Services_content {...services_content_list_1} ></Services_content>
                    </div>
                </div>
                <div className="my-4 py-2">
                    <div className="container">
                        <Services_content_right {...services_content_list_2} ></Services_content_right>
                    </div>
                </div>
                <div className="bg-light my-4 py-5">
                    <div className="container">
                        <Services_content {...services_content_list_3} ></Services_content>
                    </div>
                </div>
                <div className="my-4 py-2">
                    <div className="container">
                        <Services_content_right {...services_content_list_4} ></Services_content_right>
                    </div>
                </div>
                <div className="bg-light my-4 py-5">
                    <div className="container">
                        <Services_content {...services_content_list_5} ></Services_content>
                    </div>
                </div>
            </div> */}
            <div className="bg-light my-4 py-5">
                <div className="container">
                    <Services_content {...services_content_list_5} ></Services_content>
                </div>
            </div>
            <div className="container">
                <h5 className='mb-0'>Types of landing pages :</h5>

                <div className="pt-3">
                    <ul>
                        <li className='list-styled-circle'><span className='text-dark'>Squeeze page</span></li>
                        <li className='list-styled-circle'><span className='text-dark'>Lead capture landing page</span></li>
                        <li className='list-styled-circle'><span className='text-dark'>Splash page</span></li>
                        <li className='list-styled-circle'><span className='text-dark'>Click-through landing page</span></li>
                        <li className='list-styled-circle'><span className='text-dark'>Paid advertising landing page</span></li>
                    </ul>
                </div>
            </div>
            <div className="container">
                <h5 className='mb-0'>Landing Page & Conversion Rate Optimization :</h5>
                <div className="container pt-5 pb-3 remove_class_section_title">
                    <div className="d-flex justify-content-center mb-3">
                        <h6 className="section-title bg-white text-center text-primary px-3">Landing Page & Conversion Rate Optimization</h6>
                    </div>
                    <p>A landing page is an independent page intended to change over clients into qualified leads. People may "land" on these pages using Google search, promotions (PPC or virtual entertainment), or a source of inspiration (CTA) put on your site, blog, or in a separate email. Even though there are various kinds of greeting pages (erring on that in a moment), one thing they all share, practically speaking, is their particular concentration.</p>
                </div>

                <div className="pt-3">
                    <ul>
                        <li className='list-styled-circle'><span className='text-dark'>Single deal</span></li>
                        <p>Landing pages empower clients to learn everything without exception about your organisation and items. Every one of the advantages. Every one of the highlights. Everything. Presentation pages, then again, are constantly centred around a solitary mission to advance a particular item, administration, or offer</p>
                        <li className='list-styled-circle'><span className='text-dark'>A single objective</span></li>
                        <p>While landing pages offer clients whatever number of chances as could be expected under the circumstances to draw in — buy into the pamphlet, follow via web-based entertainment, book a demo call, talk with a chatbot, look at the most recent blog entry — greeting pages drive a solitary strategy—normal greeting page CTAs including finishing up a structure or pursuing a free preliminary</p>
                        <li className='list-styled-circle'><span className='text-dark'>Splash page</span></li>
                        <p>Since greeting pages are crusade centred, they focus on a solitary Source of traffic, arriving clients through exceptionally designated paid promotions alone. Source of traffic, frequently arriving at clients through very selected paid upgrades.</p>
                    </ul>
                </div>
            </div>
            <div className="">
                <div className="bg-light my-4 py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-6 d-md-block d-none">
                                <div className="h-100 d-flex align-items-center">
                                    <div className="w-100">
                                    <img src={img1} className='img-fluid Services_img_responsive' alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 d-flex align-items-center">
                                <div className="">
                                    <h3 className='mb-3'>YouTube Ads</h3>
                                    <h6 className='text-secondary fw-semibold content_pr'></h6>
                                    <p className='mb-1'>In this segment, we'll sort out the quirks of this kind of advancement and make sense of how YouTube Advertising</p>
                                    <p className='mb-1'>There are two kinds of YouTube advertisements: recordings and static substances (pictures in addition to messages). </p>
                                    <p className='mb-1'>The decision of the kind relies upon the points of your publicising effort, spending plan, and how you need to pay for YouTube advancements. Likewise, there are two estimating models: cost-per-view (CPV) and cost-per-engagement (CPE). CPV suggests that you pay after people watch an improvement that they can't skip, and CPE is paid when purchasers help out with an advance. </p>
                                    <p className='mb-1'>The expense depends on the publicising design you pick. Clients can watch a promotion previously or during the video, in the query items, in the primary concern of the video, and show up as a spring-up .</p>
                                    <p className='mb-1'>The out-of-notice additionally relies upon the picked design. YouTube publicising can be challenging to work with on the double, yet exceptionally fulfilling. Find the advantages of this advancement type in the following area.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { GoogleAds }