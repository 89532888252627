import React from 'react'
import { Header } from "../Header/Header"
import images from "../img/SEO-Services_.png"
import { Services_title } from './Services_title'
import { Services_content } from './services_content'
import { Services_content_right } from './services_content_right'

import img1 from "../img/SEO Services/Competitive-Keyword-Analysis.png"
import img2 from "../img/SEO Services/Increased-Online-Presence.png"
import img3 from "../img/SEO Services/Increased-Page-Speed.png"
import img4 from "../img/SEO Services/Market-Explanation.png"
import img5 from "../img/SEO Services/Page-Rank-Checker.png"
import img6 from "../img/SEO Services/Pay-Per-Click-Services.png"
import img7 from "../img/SEO Services/SEO-Friendly-Tags.png"
import img8 from "../img/SEO Services/Website-Optimization.png"
import img9 from "../img/SEO Services/Weekly-Adjustments.png"
import img10 from "../img/SEO Services/link-building.png"
import img11 from "../img/SEO Services/target-audience.png"

function SeoServices() {
    let Services_title_list = {
        h1:"SEO Services | Coupon Locus",
        p1:"Coupon Locus offers innovative, secure Information Technology solutions. Helping organisations improve their SEO Services.",
        p2:"Website design enhancement consists of numerous Understanding of the components and how they function crucial in comprehending their purpose. Search engine optimization (SEO) is so significant. To put it, Web optimization is enormous because it makes your site more apparent, implying With more traffic, there are more opportunities to convert potential customers into actual clients. Past that, it is likewise an essential device for brand mindfulness, building associations with options, and situating yourself as a definitive and reliable master in your field. So right away, here's the beginning and end. You want to be familiar with Search engine optimization and why it's fundamental in today's advanced world.",
        h2:""
    }

    
    let services_content_list_1 = {
        title: "Website Optimization",
        contact: "Utilising devices through website optimization is a commonly used method. High-level systems, and examinations to work on the exhibition of your site, further drive more traffic, increment transformations, and develop income.One of the most fundamental parts of site streamlining is website improvement (Web optimization). The method needs to spotlight getting different pages. If you desire your website to have a high ranking, then ranking, you need to optimise it. SERPs for explicit watchwords. However, it empowers possibilities to track down your image in the most straightforward way. The other fundamental viewpoint here is an on-page improvement. This strategy guarantees that prospects that land on your site have the best client experience convincing them to make the ideal move and convert into a lead",
        li: [],
        img: img8
    }
    let services_content_list_2 = {
        title: "Competitive Keyword Analysis",
        contact: "Competitor keyword analysis - here and there called keyword hole analysis - recognizes essential keywords that your rivals rank profoundly for, which you don't.A few significant focuses to consider: The keywords ought to be significant (high-volume, connected with your business, as well as liable to change over) The keywords ought to be ones you could rank for or could organise for a better Reward: Contrasting at least two contenders frequently gives you a more extravagant analysis",
        li: [],
        img: img1
    }
    let services_content_list_3 = {
        title: "Increased Online Presence",
        contact: "A web-based presence can be characterised by the fact that finding a brand or organisation on the web is so natural. It's significant for building your image's standing, expanding brand mindfulness, and giving permeability to your items or administrations when clients seek related keywords.",
        li: [],
        img: img2
    }
    let services_content_list_4 = {
        title: "Weekly Adjustments",
        contact: "Adjustment meetings aim to plan for the future and discuss how the team can improve. Utilise its dashboards to have the right conversations to make essential changes. Instead of people introducing refreshes, the entire team contributes thoughts and cooperates on answers to accomplish the organisation's plan. Status meetings give an image of what has proactively occurred in the past that you can't change. The concentration in a week-after-week status meeting is on one individual showing results or progress.",
        li: [],
        img: img9
    }
    let services_content_list_5 = {
        title: "Page Rank Checker",
        contact: "PageRank Checker is a free internet-based instrument for figuring out the PageRank of any site. However, it works like a page-score mini-computer in a considerably more supportive way. PageRank is a free proportion of Google's view of the quality, authority, and validity of a particular website page.",
        li: [],
        img: img5
    }
    let services_content_list_6 = {
        title: "Pay-Per-Click Services",
        contact: "Pay-Per-Click Administration is a type of internet publicising where sponsors pay for each snap to drive designated traffic to a particular site. PPC advertisements appear on web index results pages, virtual entertainment stages, or locations. Sponsors can target given catchphrases, socioeconomics, psychographics, or conduct information.",
        li: [],
        img: img6
    }
    let services_content_list_7 = {
        title: "Market Explanation",
        contact: "Market analysis involves a thorough assessment of various factors. A particular market. Particular market. Market inside a specific industry. You will concentrate on the elements of your market, for example, volume and worth, potential client fragments, purchasing behaviours, contests, and other significant variables.",
        li: [],
        img: img4
    }
    let services_content_list_8 = {
        title: "SEO-Friendly Tags",
        contact: "SEO-friendly content is the kind of happiness that is made such that it assists the web crawlers with positioning it high. Albeit a few advertisers figure this implies it ought to be loaded down with catchphrases, it's intended to help them with looking through motors to find, comprehend, and interface your substance to the point you're attempting to cover. Tags: The title tag, Meta description tag, Heading (H1-H6) tags, Image alt text, Schema markup, HTML5 semantic tags, Meta robots tag, Canonical tag, etc",
        li: [],
        img: img7
    }
    let services_content_list_9 = {
        title: "Target Audience",
        contact: "Your target audience refers to the particular gathering of customers who will probably need your item or administration and, thus, the gathering who ought to see your promotion crusades. The leading interest group might be directed by age, orientation, pay, area, interests, or different elements.",
        li: [],
        img: img11
    }
    let services_content_list_10 = {
        title: "Increased Page Speed",
        contact: "Page speed is a factor that calculates your site's prosperity, as it can influence your web search tool rankings, your consumer loyalty, and your change rate. If your page could be faster and more active, it may be a sign you want a new website.",
        li: [],
        img: img3
    }
    let services_content_list_11 = {
        title: "Link Building",
        contact: "Link building refers to the advertising efforts to get joins from different sites to your site. It's viewed as one of the most valuable tools to achieve higher rankings in web search tools. In an interactive discussion, Google's Andrey Lipases affirmed that backlink is a significant positioning component.",
        li: [],
        img: img10
    }
  return (
    <>
    <Header pagename="Seo Services" image={images} path={[{link:"/",text:"Home"},{link:"/seoServices",text:"Seo Services"}]}/>
    <div>
        <Services_title {...Services_title_list}></Services_title>
        <div className="pb-3"></div>
        <div className="bg-light my-4 py-5">
            <div className="container">
                <Services_content {...services_content_list_1} ></Services_content>
            </div>
        </div>
        <div className="my-4 py-2">
            <div className="container">
                <Services_content_right {...services_content_list_2} ></Services_content_right>
            </div>
        </div>
        <div className="bg-light my-4 py-5">
            <div className="container">
                <Services_content {...services_content_list_3} ></Services_content>
            </div>
        </div>
        <div className="my-4 py-2">
            <div className="container">
                <Services_content_right {...services_content_list_4} ></Services_content_right>
            </div>
        </div>
        <div className="bg-light my-4 py-5">
            <div className="container">
                <Services_content {...services_content_list_5} ></Services_content>
            </div>
        </div>
        <div className="my-4 py-2">
            <div className="container">
                <Services_content_right {...services_content_list_6} ></Services_content_right>
            </div>
        </div>
        <div className="bg-light my-4 py-5">
            <div className="container">
                <Services_content {...services_content_list_7} ></Services_content>
            </div>
        </div>
        <div className="my-4 py-2">
            <div className="container">
                <Services_content_right {...services_content_list_8} ></Services_content_right>
            </div>
        </div>
        <div className="bg-light my-4 py-5">
            <div className="container">
                <Services_content {...services_content_list_9} ></Services_content>
            </div>
        </div>
        <div className="my-4 py-2">
            <div className="container">
                <Services_content_right {...services_content_list_10} ></Services_content_right>
            </div>
        </div>
        <div className="bg-light my-4 py-5">
            <div className="container">
                <Services_content {...services_content_list_11} ></Services_content>
            </div>
        </div>
        
    </div>
    </>
  )
}

export {SeoServices}