import { useState } from "react"
import  About from "../img/about.jpg"
function AdvanceCard(props) {
    
   let [Data,updateData] = useState(props.data)
    return <>
    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{minHeight:"400px"}}>
                    <div className="position-relative h-100">
                        <img className="img-fluid position-absolute w-100 h-100" src={About} alt="" style={{objectFit:"cover"}} />
                    </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                    <h6 className="section-title bg-white text-start text-primary pe-3">About Us</h6>
                    <h1 className="mb-4">{Data.mainTitle}</h1>
                    {Data.aboutUsMainContent.map((e,i) => {
                        return (
                            <p key={i + 25896} className="mb-4">{e}</p>
                        )
                    })}                  
                    <div className="row gy-2 gx-4 mb-4">
                    {Data.aboutuslist.map((e,i) => {
                            return( 
                                <div key={i + 25896} className="col-sm-12">
                                    <p className="mb-0" key={i + 25896}><i className="fa fa-arrow-right text-primary me-2"></i>{e}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    </>
}

export {AdvanceCard}