import { Link } from "react-router-dom"
import img from '../img/carousel-1.jpg'


function Header(props){
    return (<div style={{paddingTop:"75px"}}
    >
        <div className="container-fluid bg-primary px-0 mb-5 page-header" style={ props.image ?  {backgroundImage:`url(${props.image})`} :  {backgroundImage:`url(${img})`}}>
        <div className="back_layer py-5">
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-lg-10 text-center">
                        <h1 className="display-3 text-white animated slideInDown">{props.pagename}</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                {props.path.map((e,i) => {
                                    if(i !== props.path.length -1 ){
                                    return   <li className="breadcrumb-item" key={i + 8569}><Link className="text-white" key={i + 8569}  to={e.link}>{e.text}</Link></li>  
                                    }else{
                                        return <li className="breadcrumb-item text-white active" key={i + 25001} aria-current={i === props.path.length -1 ? "page" : ""} >{e.text}</li> 
                                    }
                                    })}
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>)
}

export {Header}






